<template>
  <div
    class="container-file"
    :class="{ success: success, error: errorFiileSizes.file1 || error }"
  >
    <div @click="srcFile" v-show="showImage" class="canvas">
      <img class="img-visible" :src="imgSrc" alt="canvas" />
      <canvas ref="canvas"></canvas>
    </div>
    <input
      type="file"
      @change="uploadFile1"
      ref="upload1"
      class="inputfile"
      :id="idInput"
    />
    <div class="wrap">
      <label v-if="isNoSlot" :for="idInput">{{ fiileSizes.file1 }}</label>
      <label v-if="!isNoSlot" :for="idInput"><slot></slot></label>
      <div class="icon" v-if="iconPath">
        <svg
          width="2.13rem"
          height="2.13rem"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            :d="iconPath"
            fill="#377DFF"
          />
        </svg>
      </div>
    </div>
    <div v-if="errorFiileSizes.file1 || error" class="no-valid">
      {{ $t("register.errorFile") }}
    </div>
    <div v-if="fileFormat || error" class="no-valid">
      {{ $t("register.errorFileFormat") }}
    </div>
    <button v-if="showModal" @click="showModal = false" class="fixed-btn">
      <Icon icon="clarity:window-close-line" :inline="true" class="icon-link" />
    </button>
    <modal-container class="wrapper-img" v-if="showModal">
      <img :src="imgSrc" alt="Image" />
    </modal-container>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { useLanguage } from "@/utils/language";
import ModalContainer from "../ModalContainer.vue";
import { Icon } from "@iconify/vue";
export default {
  components: { ModalContainer, Icon },
  props: {
    idInput: {
      type: String,
      require: true,
    },
    error: {
      type: Boolean,
      require: true,
      default: false,
    },
    size: {
      type: Number,
      default: 2,
    },
    isFace: {
      type: Boolean,
      default: false,
    },
    isNoSlot: {
      type: Boolean,
      default: true,
    },
    fileName: {
      type: String,
      require: true,
    },
    iconPath: String,
  },
  // setup(props, { emit }) {
  setup(props, { emit }) {
    const { i18n } = useLanguage();
    const upload1 = ref(null);
    const addFoto = i18n.global.t("register.fotosBtn");

    const width = 354;
    const height = 472;

    const showImage = ref(false);
    const showModal = ref(false);

    const bolbFile = ref(null);
    const canvas = ref(null);

    const success = ref(false);

    const fileFormat = ref(false);

    let ctx;
    const drawImage = (url) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = url;
        img.onload = () => {
          // draw image on canvas fitted and cropped to 354x472
          const scale = Math.max(width / img.width, height / img.height);
          const x = width / 2 - (img.width / 2) * scale;
          const y = height / 2 - (img.height / 2) * scale;
          resolve(ctx.drawImage(img, x, y, img.width * scale, img.height * scale));
        };
        // const image = new Image();
        // image.src = url;
        // image.onload = () => {
        //   resolve(ctx.drawImage(image, 0, 0, width, height));
        // };
      }).then(() => {
        return ctx;
      });
    };

    onMounted(() => {
      ctx = canvas.value.getContext("2d");
      canvas.value.width = width;
      canvas.value.height = height;
    });

    const imgSrc = ref(null);
    const srcFile = () => {
      const reader = new FileReader();
      reader.readAsDataURL(bolbFile.value);
      reader.onload = async (e) => {
        if (props.isFace == true) {
          imgSrc.value = await e.target.result;
        }
        showModal.value = true;
      };
    };

    const getImg = (data) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = async (e) => {
          await drawImage(e.target.result);
          imgSrc.value = e.target.result;

          canvas.value.toBlob(async (blob) => {
            resolve(new File([blob], `${props.fileName}.jpg`));
          }, "image/jpeg");
        };
      }).then((file) => {
        return file;
      });

    const fiileSizes = reactive({
      file1: addFoto,
    });

    const errorFiileSizes = reactive({
      file1: false,
    });

    function formatBytes(bytes) {
      var kb = 1024 * 1024;
      var ndx = bytes / kb;
      return ndx;
    }

    const validateimg = async (ctrl) => {
      var fileUpload = ctrl;
      const nameFoto = fileUpload.files[0].name;
      const sizeMb = formatBytes(fileUpload.files[0].size);
      if (props.isFace) {
        showImage.value = true;
        bolbFile.value = await getImg(fileUpload.files[0]);
        if (typeof fileUpload.files != "undefined") {
          errorFiileSizes.file1 = false;
          success.value = true;
          fiileSizes.file1 = nameFoto;
          emit("getImg", bolbFile.value);
        } else {
          errorFiileSizes.file1 = true;
          fiileSizes.file1 = addFoto;
          success.value = false;
          showImage.value = false;

          // alert("This browser does not support HTML5.");
          return;
        }
        return;
      }

      var regex = new RegExp("()+(.jpg|.jpeg|.JPG|.JPEG)$");
      if (regex.test(fileUpload.value.toLowerCase())) {
        bolbFile.value = await getImg(fileUpload.files[0]);

        if (sizeMb > props.size || sizeMb < 0.2) {
          showImage.value = false;
          // alert("Please select a valid size Image file.", );

          errorFiileSizes.file1 = true;
          fiileSizes.file1 = addFoto;
          return;
        }
        errorFiileSizes.file1 = false;
        fiileSizes.file1 = nameFoto;
        showImage.value = true;
        success.value = true;

        emit("getImg", fileUpload.files[0]);
      } else {
        fileFormat.value = true;
        showImage.value = false;
        success.value = false;
        fiileSizes.file1 = addFoto;
      }
    };

    const uploadFile1 = () => {
      fileFormat.value = false;
      errorFiileSizes.file1 = false;
      fiileSizes.file1 = "";
      validateimg(upload1.value);
    };

    return {
      uploadFile1,
      errorFiileSizes,
      fiileSizes,
      upload1,
      canvas,
      showImage,
      srcFile,
      success,
      showModal,
      imgSrc,
      fileFormat,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper-img {
  width: 100%;
  // margin: 0 auto;
  // padding: 50%;

  img {
    width: auto;
    height: 80vh;
    display: block;
    margin: 0 auto;
  }
}
.fixed-btn {
  position: fixed;
  right: 5vw;
  top: 5vh;
  z-index: 100;
  background: transparent;
  cursor: pointer;
  .icon-link {
    font-size: 2em;
    color: #e6e6e6;
  }
}

.no-valid {
  margin-top: 0.31rem;
}
.canvas {
  cursor: pointer;
  // height: 114px;
  position: relative;

  border: 0.125rem solid #bec1ce;
  border-radius: 0.625rem;
  overflow: hidden;
  width: 12.1875rem;
  height: 16rem;
  margin-bottom: 0.63rem;
  // padding-bottom: 52.25%;
  .img-visible {
    margin: 0 !important;
    object-fit: cover;
  }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    // height: auto;
  }
}
.container-file {
  position: relative;

  &.success {
    svg {
      path {
        fill: var(--success-color);
      }
    }

    .inputfile + .wrap label {
      border-color: var(--success-color);
      color: var(--success-color);
    }
  }

  &.error {
    svg {
      path {
        fill: var(--error-color);
      }
    }

    .inputfile + .wrap label {
      border-color: var(--error-color);
      color: var(--error-color);
    }
  }
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.wrap {
  position: relative;
  height: max-content;
  width: 100%;
  .icon {
    width: 2.125rem;
    height: 2.125rem;
    position: absolute;
    top: 50%;
    right: 1.625rem;
    transform: translateY(-50%);
  }
}

.inputfile + .wrap label {
  width: 100%;
  font-size: 1.25em;
  font-weight: 400;
  color: var(--disabled-color);
  background: #f3f5f8;
  border: 0.06rem solid transparent;
  display: block;
  margin: 0;
  padding: 1.25rem 1.625rem;
  border-radius: 0.625rem;
}

.inputfile:focus + .wrap label,
.inputfile + .wrap label:hover {
  // background-color: red;
}
.inputfile + .wrap label {
  cursor: pointer; /* "hand" cursor */
}
</style>
